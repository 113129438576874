.gold-jewelleries-container {
    margin-top: 220px;
    padding-top: 0;
}

.new-gold-items-section {
    margin: 40px 0;
    padding: 40px 20px;

}

.new-gold-items-section h2 {
    text-align: center;
    font-size: 3em;
    margin-bottom: 30px;
    color: #1E90FF;
    font-family: Georgia;
}

.new-image-cards-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    margin-top: 30px;
}

.img-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    text-align: center;
}

.img-card:hover {
    transform: scale(1.05);
}

.img-card img {
    max-width: 100%;
    height: 400px;
    border-radius: 8px;
    margin-bottom: 15px;
}

.img-card h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #333;
    font-family: Georgia;
}

.img-card p {
    font-size: 1rem;
    color: #666;
    margin-bottom: 15px;
}

.view-product-btn {
    background-color: #DAA520;
    color: white;
    border: none;
    padding: 12px 20px;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.view-product-btn:hover {
    background-color: #C0C0C0;
}

.view-product-btn:focus {
    outline: none;
}

/* GoldJewelleries.css */

/* Other Products Section */
.other-products-section {
    margin: 50px 0;
    text-align: center;
}

.other-products-section h2 {
    font-size: 3rem;
    font-family: Georgia;
    margin-bottom: 20px;
    color: #1E90FF;
}

.other-products-container {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.other-product-card {
    position: relative;
    overflow: hidden;
    width: 300px;
    height: 200px;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}

.other-product-card:hover {
    transform: scale(1.05);
}

.other-product-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.other-product-card h3 {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    font-size: 1.2rem;
    text-align: center;
    transition: background-color 0.3s ease-in-out;
}

.other-product-card:hover h3 {
    background-color: rgba(0, 0, 0, 0.9);
}

@media (max-width: 768px) {
    .new-image-cards-container {
        grid-template-columns: 1fr;
        /* One card per row */
        gap: 20px;
    }

    .new-gold-items-section {
        margin-top: 100px;
    }

    .img-card img {
        height: 250px;
        /* Adjust image height for smaller screens */
    }

    .new-gold-items-section h2 {
        font-size: 3rem;
        /* Adjust heading size for smaller screens */
    }

    .view-product-btn {
        font-size: 0.9rem;
        /* Smaller button font size for mobile */
        padding: 10px 15px;
        /* Adjust button padding for mobile */
    }

    .other-products-container {
        display: flex;
        flex-direction: column;
        /* Stack the cards vertically */
        align-items: center;
        /* Center the cards */
    }

    .other-product-card {
        width: 100%;
        /* Ensure cards take up full width */
        max-width: 300px;
        /* Set max width for each card */
        height: 250px;
        /* Adjust height for mobile */
    }

    .other-product-card h3 {
        font-size: 1rem;
        /* Adjust title font size for mobile */
    }

    .other-products-section h2 {
        font-size: 3rem;
        /* Adjust heading font size for mobile */
    }
}