.silver-cards-section {
    margin-top: 0%;
    margin-bottom: 20px;
    text-align: center;
}

.silver-cards-container {
    display: flex;
    justify-content: center;
    /* Center the cards horizontally */
    gap: 20px;
    /* Adjust the spacing between the cards */
    flex-wrap: wrap;
    /* Wrap the cards if necessary */
}

.silver-cards-card {
    position: relative;
    overflow: hidden;
    width: 450px;
    /* Increased the width of the card */
    height: 450px;
    /* Adjusted height for proportion */
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    margin-top: 20px;
}

.silver-cards-card:hover {
    transform: scale(1.05);
}

.silver-cards-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.silver-cards-card h3 {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    font-size: 1.2rem;
    text-align: center;
    transition: background-color 0.3s ease-in-out;
}

.silver-cards-card:hover h3 {
    background-color: rgba(0, 0, 0, 0.9);
}

/* Ensure only three cards are displayed in a row and are centered */
.silver-cards-container {
    max-width: calc(450px * 3 + 40px * 2);
    /* Total width for 3 cards with gaps */
    margin: 0 auto;
    /* Center the cards container */
}

/* Mobile responsiveness (max-width: 768px) */
@media (max-width: 768px) {
    .silver-cards-container {
        display: flex;
        flex-direction: column;
        /* Stack the cards vertically */
        align-items: center;
        /* Center the cards */
        gap: 20px;
        /* Adjust the spacing between the cards */
    }

    .silver-cards-card {
        width: 90%;
        /* Make each card take up full width */
        max-width: 450px;
        /* Set max width to 450px */
        height: 350px;
        /* Adjust height for mobile */
    }

    .silver-cards-card h3 {
        font-size: 1rem;
        /* Adjust font size of title for mobile */
    }

    .silver-cards-section {
        margin-top: 0;
        margin-bottom: 20px;
        text-align: center;
    }
}