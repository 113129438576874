.navbar {
    width: 100%;
    font-family: Arial, sans-serif;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: top 0.3s ease;
}

.navbar.hide {
    top: -175px;
}

.navbar.show {
    top: 0;
}

/* Top section */
.top-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background: linear-gradient(to bottom, #4E53E5, #9294EB, #B6BAF0, #FFFFFF);
    color: #000000;
    border-bottom: 2px solid #808080;
    max-height: 180px;
}

.shop-name {
    flex: 1;
    text-align: left;
    font-size: 1.2rem;
    margin-left: 60px;
}

.shop-name h2 {
    margin: 0;
    font-family: Brush script mt;
    font-size: 3em;
}

.shop-name h4 {
    margin: 0;
    font-family: Georgia;
    font-size: 0.7em;
    margin-left: 10px;
}

.shop-name p {
    margin: 0;
    font-family: Courier New;
    margin-left: 25px;
    font-size: 0.8em;
}

.logo-container {
    flex: 1;
    text-align: center;
}

.logo {
    height: 150px;
    width: auto;
}

.icons {
    flex: 1;
    text-align: right;
}

.icon {
    font-size: 1.5rem;
    font-weight: bold;
    margin-left: 15px;
    margin-top: 80px;
    cursor: pointer;
    fill: none;
    /* Removes the fill */
    stroke: black;
    /* Sets the outline color to black */
    stroke-width: 35;
    /* Makes the outline stronger */
}

.icon:hover {
    color: #808080;
}

/* Bottom section */
.bottom-section {
    background-color: #FFFFFF;
    border-top: #000000;
    position: relative;
}

.nav-links {
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 10px 0;
}

.nav-item {
    margin: 0 20px;
}

.nav-link {
    color: #000000;
    text-decoration: none;
    font-size: 1.5em;
    font-family: Copperplate;
    font-weight: 500;
    transition: color 0.3s;
}

.nav-link.active {
    text-decoration: underline;
}

.nav-link:hover {
    text-decoration: underline;
}

/* Mobile styles */
.mobile-menu-button {
    display: none;
    position: absolute;
    top: 25px;
    right: 20px;
    font-size: 2rem;
    background: transparent;
    border: none;
    color: #000000;
    z-index: 1001;
    cursor: pointer;
}

@media (max-width: 768px) {
    .mobile-menu-button {
        display: block;
    }

    .navbar {

        width: 100%;
    }

    .top-section {
        max-height: 120px;
    }

    .shop-name {
        margin-left: 0;
    }

    .shop-name h2 {
        margin: 0;

        font-size: 1.5em;
    }

    .shop-name h4 {
        margin: 0;
        font-family: Georgia;
        font-size: 0.4em;

    }

    .shop-name p {
        margin: 0;
        font-family: Courier New;
        margin-left: 10px;
        font-size: 0.5em;
    }

    .logo {
        height: 100px;
        width: auto;
        margin-top: 20px;
    }

    .icons {
        margin-top: 30px;
    }

    .icon {
        font-size: 1em;
        margin-right: 30px;
        margin-top: 5px;
    }

    .nav-links {
        display: none;
        margin-right: 50px;
    }
}

.mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    /* Set background to white */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* Align text to the left */
    justify-content: center;
    z-index: 1000;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    animation: fadeIn 0.3s ease-in-out;
    padding-left: 20px;
    /* Add padding to the left for better alignment */
    text-align: left;
}


@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.close-menu {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 2rem;
    color: white;
    background: none;
    border: none;
    cursor: pointer;
    transition: color 0.3s;
}

.close-menu:hover {
    color: #FFD700;
    /* Highlighted color on hover */
}

.mobile-nav-links {
    list-style: none;
    text-align: left;
    padding: 0;
    width: 100%;
}

.mobile-nav-links .nav-link {
    font-size: 1.8rem;
    margin: 25px 0;
    text-decoration: none;
    color: black;
    font-family: Copperplate;
    transition: color 0.3s, transform 0.2s;
    margin-top: 20px;

}

.mobile-nav-links .nav-link:hover {
    color: #FFD700;
    transform: scale(1.1);
}

.mobile-nav-links .nav-link:active {
    color: #00FA9A;
}