/* General styling */
.our-products-container {
    font-family: Arial, sans-serif;
    position: relative;
    margin-top: 220px;
}

/* Navbar */
.productsbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #fff;
    border-bottom: 1px solid #eaeaea;
}

.productsbar-left .menu-button {
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #333;
}

.menu-icon {
    font-size: 20px;
    margin-right: 5px;
}

.productsbar-center .productsbar-logo {
    font-family: serif;
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

.productsbar-right .productsbar-icon {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    margin-left: 10px;
    color: #333;
}

/* Main Side Menu */
.side-menu-container {
    display: flex;
    width: 600px;
    /* Adjust the width of both sidebars together */
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 1000;
}

.side-menu {
    width: 300px;
    background-color: #fff;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
    padding: 20px;
}

.close-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    margin-bottom: 20px;
    color: #333;
}

.menu-list {
    list-style: none;
    padding: 0;
}

.menu-list li {
    font-size: 18px;
    padding: 15px 0;
    border-bottom: 1px solid #eaeaea;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
}

.menu-list li:hover,
.menu-list li:active {
    text-decoration: underline;
    text-decoration-color: black;
}

/* Add styling for the active state */
/* Add styling for the active state */
.menu-list li.active {
    text-decoration: underline;
    text-decoration-color: black;
}

/* Style for the arrow in the active menu item */
.menu-list li .arrow {
    font-size: 18px;
    margin-left: 10px;
    color: #333;
}

/* Optional: Change the hover effect for active items */
.menu-list li.active:hover {
    background-color: #f0f0f0;
}

/* Optional: Add a hover effect for the arrow */
.menu-list li:hover .arrow {
    color: #007bff;
    /* Blue color for hover */
}



.menu-list li svg {
    margin-right: 10px;
    /* Space between icon and text */
}

/* Right Side Submenu */
.right-side-menu {
    width: 300px;
    background-color: #f9f9f9;
    padding: 20px;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
}

.sub-menu {
    list-style: none;
    padding-left: 20px;
    margin-top: 10px;
    display: block;
}

.sub-menu li {
    padding: 10px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.sub-menu li svg {
    margin-right: 10px;
    /* Space between icon and text */
}

.sub-menu li:hover {
    background-color: #f0f0f0;
}

/* Vertical Divider - Displayed Only When a Category is Active */
.divider {
    width: 1px;
    background-color: #ddd;
    height: 100%;
    margin: 0 20px;
    display: block;
}

/* Overlay */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

/* Style the new section */
.gold-jewellery-section {
    display: flex;
    justify-content: space-between;
    margin: 50px 0;
    /* Adjust margin as needed */
    padding: 20px;
    background-color: #EEC864;
}

.gold-jewellery-left {
    flex: 1;
    padding-right: 20px;
    max-width: 60%;
    margin-left: 10%;
    margin-top: 8%;
}

.gold-jewellery-left h2 {
    font-size: 2rem;
    margin-bottom: 10px;
    font-family: 'Times New Roman', Times, serif;
    font-size: 3em;
}

.gold-jewellery-left p {
    font-size: 1.1rem;
    margin-bottom: 20px;
    font-family: Copperplate;
}



.view-more-button {
    text-decoration: none;
    background-color: #DAA520;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: bold;

}

.view-more-button:hover {
    background-color: #e6b800;
}

.gold-jewellery-right {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 50px;
}

.gold-jewellery-image {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
}

.silver-jewellery-section {
    display: flex;
    justify-content: space-between;
    margin: 50px 0;
    /* Adjust margin as needed */
    padding: 20px;
    background-color: #D3D3D3;
}

.silver-jewellery-left {
    flex: 1;
    padding-right: 20px;
    max-width: 60%;
    margin-left: 10%;
    margin-top: 8%;
}

.silver-jewellery-left h2 {
    font-size: 2rem;
    margin-bottom: 10px;
    font-family: 'Times New Roman', Times, serif;
    font-size: 3em;
}

.silver-jewellery-left p {
    font-size: 1.1rem;
    margin-bottom: 20px;
    font-family: Copperplate;
}



.view-more-button-2 {
    text-decoration: none;
    background-color: #A9A9A9;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: bold;

}

.view-more-button-2:hover {
    background-color: #e6b800;
}

.silver-jewellery-right {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 50px;
}

.silver-jewellery-image {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
}

.precious-stones-section {
    display: flex;
    justify-content: space-between;
    margin: 50px 0;
    /* Adjust margin as needed */
    padding: 20px;
    background-color: #DCDCDC;
}

.precious-stones-left {
    flex: 1;
    padding-right: 20px;
    max-width: 60%;
    margin-left: 10%;
    margin-top: 8%;
}

.precious-stones-left h2 {
    font-size: 2rem;
    margin-bottom: 10px;
    font-family: 'Times New Roman', Times, serif;
    font-size: 3em;
}

.precious-stones-left p {
    font-size: 1.1rem;
    margin-bottom: 20px;
    font-family: Copperplate;
}



.view-more-button-3 {
    text-decoration: none;
    background-color: #A9A9A9;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: bold;

}

.view-more-button-3:hover {
    background-color: #e6b800;
}

.precious-stones-right {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 50px;
}

.precious-stones-image {
    max-width: 80%;
    height: auto;
    border-radius: 10px;
}

.semi-precious-stones-section {
    display: flex;
    justify-content: space-between;
    margin: 50px 0;
    /* Adjust margin as needed */
    padding: 20px;
    background-color: #FFFAFA;
}

.semi-precious-stones-left {
    flex: 1;
    padding-right: 20px;
    max-width: 60%;
    margin-left: 10%;
    margin-top: 8%;
}

.semi-precious-stones-left h2 {
    font-size: 2rem;
    margin-bottom: 10px;
    font-family: 'Times New Roman', Times, serif;
    font-size: 3em;
}

.semi-precious-stones-left p {
    font-size: 1.1rem;
    margin-bottom: 20px;
    font-family: Copperplate;
}



.view-more-button-4 {
    text-decoration: none;
    background-color: #A9A9A9;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: bold;

}

.view-more-button-4:hover {
    background-color: #e6b800;
}

.semi-precious-stones-right {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 50px;
}

.semi-precious-stones-image {
    max-width: 80%;
    height: auto;
    border-radius: 10px;
}

@media screen and (max-width: 768px) {
    .our-products-container {
        margin-top: 250px;
        /* Reduce margin for smaller screens */
    }

    /* Navbar */
    .productsbar {
        flex-direction: column;
        padding: 10px;
        align-items: flex-start;
        /* Stack navbar items */
    }

    .productsbar-left,
    .productsbar-center,
    .productsbar-right {
        width: 100%;
        /* Ensure full-width navbar elements */
        text-align: center;
        margin-bottom: 10px;
        /* Add spacing between sections */
    }

    .productsbar-center .productsbar-logo {
        font-size: 20px;
        /* Adjust font size for smaller screens */
    }

    /* Side Menu */
    .side-menu-container {
        width: 100%;
        /* Full-width side menu on mobile */
    }

    .side-menu {
        width: 100%;
        /* Expand side menu to full width */
        padding: 10px;
        /* Adjust padding */
    }

    .close-button {
        font-size: 20px;
        /* Adjust close button size */
    }

    .menu-list li {
        font-size: 16px;
        /* Reduce font size for menu items */
        padding: 10px 0;
    }

    .menu-list li .arrow {
        font-size: 16px;
        /* Adjust arrow size */
    }

    .right-side-menu {
        width: 100%;
        /* Full-width submenu */
        padding: 10px;
        /* Adjust padding */
        box-shadow: none;
        /* Remove shadow for cleaner mobile look */
    }

    .divider {
        display: none;
        /* Hide divider for mobile screens */
    }

    /* Submenu */
    .sub-menu {
        padding-left: 10px;
        /* Reduce padding */
        margin-top: 5px;
        /* Adjust spacing */
    }

    .sub-menu li {
        padding: 8px 0;
        /* Adjust padding */
        font-size: 14px;
        /* Reduce font size */
    }

    .sub-menu li svg {
        font-size: 14px;
        /* Adjust icon size */
    }

    .gold-jewellery-section {
        flex-direction: column;
        /* Stack items vertically */
        padding: 10px;
        /* Reduce padding */
        margin: 20px 0;
        /* Adjust margin */
    }

    .gold-jewellery-right {
        margin-right: 0;
        /* Remove extra margin for better centering */
        margin-bottom: 20px;
        /* Add spacing between image and text section */
        justify-content: center;
    }

    .gold-jewellery-left {
        margin: 0;
        /* Remove custom margins */
        padding: 0 10px;
        /* Add padding for text content */
        max-width: 100%;
        /* Utilize full width for content */
        text-align: center;
        /* Center-align text */
    }

    .gold-jewellery-left h2 {
        font-size: 2rem;
        /* Adjust heading size for small screens */
    }

    .gold-jewellery-left p {
        font-size: 1rem;
        /* Adjust paragraph font size */
        margin-bottom: 15px;
        /* Adjust spacing */
    }

    .view-more-button {
        font-size: 0.9rem;
        /* Adjust button size */
        padding: 8px 16px;
        /* Reduce padding */

    }

    .gold-jewellery-image {
        width: 80%;
        /* Reduce image size for better fit */
        margin-top: 20px;
        /* Center image */
    }

    .silver-jewellery-section {
        flex-direction: column;
        /* Stack items vertically */
        padding: 10px;
        /* Reduce padding for smaller screens */
        margin: 20px 0;
        /* Adjust margin */
    }

    .silver-jewellery-right {
        margin-right: 0;
        /* Remove extra margin */
        margin-bottom: 20px;
        /* Add space between image and text */
        justify-content: center;
        /* Center the image */
    }

    .silver-jewellery-left {
        margin: 0;
        /* Remove custom margins */
        padding: 0 10px;
        /* Add padding for better spacing */
        max-width: 100%;
        /* Utilize full width for text */
        text-align: center;
        /* Center-align text */
    }

    .silver-jewellery-left h2 {
        font-size: 2rem;
        /* Adjust heading size for mobile */
    }

    .silver-jewellery-left p {
        font-size: 1rem;
        /* Adjust paragraph font size */
        margin-bottom: 15px;
        /* Adjust spacing */
    }

    .view-more-button-2 {
        font-size: 0.9rem;
        /* Adjust button size */
        padding: 8px 16px;
        /* Reduce button padding */
    }

    .silver-jewellery-image {
        width: 80%;
        /* Adjust image size for better fit */
        margin-top: 20px;
        /* Center the image */
    }

    .precious-stones-section {
        flex-direction: column;
        /* Stack content vertically */
        padding: 10px;
        /* Reduce padding for smaller screens */
        margin: 20px 0;
        /* Adjust margin */
    }

    .precious-stones-right {
        margin-right: 0;
        /* Remove extra right margin */
        margin-bottom: 20px;
        /* Add spacing below the image */
        justify-content: center;
        /* Center-align the image */
    }

    .precious-stones-left {
        margin: 0;
        /* Remove custom margins */
        padding: 0 10px;
        /* Add padding for better spacing */
        max-width: 100%;
        /* Utilize full width for text */
        text-align: center;
        /* Center-align the text */
    }

    .precious-stones-left h2 {
        font-size: 2rem;
        /* Adjust heading size for mobile */
    }

    .precious-stones-left p {
        font-size: 1rem;
        /* Adjust paragraph font size */
        margin-bottom: 15px;
        /* Adjust spacing */
    }

    .view-more-button-3 {
        font-size: 0.9rem;
        /* Adjust button size */
        padding: 8px 16px;
        /* Reduce button padding */
    }

    .precious-stones-image {
        width: 80%;
        /* Adjust image size for better fit */
        margin-top: 20px;
        /* Center the image */
    }

    .semi-precious-stones-section {
        flex-direction: column;
        /* Stack content vertically */
        padding: 10px;
        /* Reduce padding for smaller screens */
        margin: 20px 0;
        /* Adjust margin */
    }

    .semi-precious-stones-right {
        margin-right: 0;
        /* Remove extra right margin */
        margin-bottom: 20px;
        /* Add spacing below the image */
        justify-content: center;
        /* Center-align the image */
    }

    .semi-precious-stones-left {
        margin: 0;
        /* Remove custom margins */
        padding: 0 10px;
        /* Add padding for better spacing */
        max-width: 100%;
        /* Utilize full width for text */
        text-align: center;
        /* Center-align the text */
    }

    .semi-precious-stones-left h2 {
        font-size: 2rem;
        /* Adjust heading size for mobile */
    }

    .semi-precious-stones-left p {
        font-size: 1rem;
        /* Adjust paragraph font size */
        margin-bottom: 15px;
        /* Adjust spacing */
    }

    .view-more-button-4 {
        font-size: 0.9rem;
        /* Adjust button size */
        padding: 8px 16px;
        /* Reduce button padding */
    }

    .semi-precious-stones-image {
        width: 80%;
        /* Adjust image size for better fit */
        margin-top: 20px;
        /* Center the image */
    }
}