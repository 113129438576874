.precious-stones-container {


    margin-top: 220px;
}

.sapphire-colors {
    text-align: center;
    margin-bottom: 30px;
    max-height: 300px;
}

.sapphire-colors h1 {
    text-align: center;
    font-size: 4em;
    font-family: Georgia;
    margin-bottom: 0%;
}

.sapphire-colors h2 {
    font-size: 2em;
    /* Larger heading for emphasis */
    font-weight: 600;
    color: #2c3e50;
    /* Darker text color for better readability */
    margin-top: 0%;
    text-align: center;
    text-decoration: underline;
    font-family: Monaco;
}

.sapphire-details {
    display: flex;
    justify-content: space-between;
    /* Space between text and image */
    align-items: center;
    /* Align items vertically */
    flex-wrap: wrap;
    /* Allow wrapping on smaller screens */
    gap: 20px;
    /* Add some space between elements */
    max-height: 300px;
}

.sapphire-text {
    flex: 1;
    text-align: left;
    padding-right: 30px;
    color: #34495e;
    /* Slightly different gray for text */
    font-size: 1.1em;
    /* Slightly larger text for readability */
    line-height: 1.6;
    /* More space between lines for better readability */
    max-width: 55%;
}

.sapphire-image-container {
    position: relative;
    max-width: 350px;
    /* Set maximum width for image */
    max-width: 40%;
    display: flex;
    justify-content: center;
    /* Center the image horizontally */
    align-items: center;
    /* Center the image vertically */
}

.sapphire-image {
    width: 80%;
    /* Make the image responsive */
    height: auto;
    max-width: 320px;
    /* Set max width for image */
    border-radius: 8px;
    /* Rounded corners */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    /* Softer shadow for depth */
    object-fit: cover;
    /* Ensure the image covers the area without distortion */
    margin-top: 30px;
    margin-right: 250px;
}

.color-options {
    position: absolute;
    top: 60%;
    right: 100px;
    /* Shift buttons more to the right */
    transform: translateY(-50%);
    /* Center vertically */
    display: flex;
    flex-direction: column;
    /* Stack buttons vertically */
    gap: 15px;
    /* Increase spacing between buttons */
    align-items: flex-end;
    /* Align buttons to the right */
}

.color-options button {
    padding: 15px 25px;
    font-size: 1.1rem;
    background-color: #ffffff;
    border: 2px solid #ccc;
    border-radius: 6px;
    cursor: pointer;
    color: #34495e;
    font-weight: 500;
    transition: all 0.3s ease;
    width: 100%;
    /* Make buttons full width */
    max-width: 160px;
    /* Limit button width */
    text-align: center;
    /* Center button text */
}

.color-options button:hover {
    background-color: #f1f1f1;
    border-color: #bbb;
}

.color-options button.active {
    background-color: black;
    /* Active button color */
    color: #ffffff;
    font-weight: bold;
    /* White text on active */
    border-color: black;
    /* Darker border on active */
    transform: translateX(-15px);
    /* Shift the active button to the left */
}

.sapphire-colors p {
    text-align: justify;
    margin-top: 25px;
    color: #34495e;
    font-size: 1.1em;
    line-height: 1.6;
    padding-left: 20px;
    padding-right: 20px;
}

/* Section for precious stones gallery */
.pre-stones-gallery {
    margin-top: 250px;
    /* Adjust top margin for spacing */
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    /* Ensure it takes full width of the container */
}



.stone-cards-section {
    margin-top: 250px;

    margin-bottom: 20px;
    text-align: center;
}



.stone-cards-container {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.stone-cards-card {
    position: relative;
    overflow: hidden;
    width: 400px;
    height: 400px;
    margin-left: 50px;
    margin-right: 50px;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}

.stone-cards-card:hover {
    transform: scale(1.05);
}

.stone-cards-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.stone-cards-card h3 {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    font-size: 1.2rem;
    text-align: center;
    transition: background-color 0.3s ease-in-out;
}

.stone-cards-card:hover h3 {
    background-color: rgba(0, 0, 0, 0.9);
}

footer {
    width: 100%;
    background-color: #333;
    color: #fff;
    padding: 20px 0;
    text-align: center;
    box-sizing: border-box;
    position: relative;
    /* Optional: to handle stacking contexts */
}


/* Mobile responsiveness */
@media (max-width: 768px) {

    /* Precious Stones Container */
    .precious-stones-container {
        margin-top: 250px;
        /* Adjust top margin for smaller screens */
    }

    /* Sapphire Colors */
    .sapphire-colors {
        margin-bottom: 20px;
        max-height: none;
        /* Remove max height to allow flexibility */
    }

    .sapphire-colors h1 {
        font-size: 3.5em;
        /* Smaller font size for mobile */
        margin-bottom: 20px;
    }

    .sapphire-colors h2 {
        font-size: 1.5em;
        /* Smaller heading size */
        text-align: center;
        margin-top: 10px;
    }

    /* Sapphire Details */
    .sapphire-details {
        display: flex;
        flex-direction: column;
        /* Stack elements vertically */
        justify-content: center;
        align-items: center;
        gap: 20px;
        max-height: none;
        /* Allow details to take full space */
    }

    .sapphire-text {
        flex: 1;
        text-align: center;
        /* Center text for mobile */
        padding-right: 0;
        padding-left: 20px;
        /* Padding on both sides for readability */
        color: #34495e;
        font-size: 1em;
        line-height: 1.6;
        max-width: 100%;
    }

    .sapphire-image-container {
        margin-right: 0%;
        max-width: 100%;
        /* Allow image to take full width on mobile */
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .sapphire-image {
        width: 80%;
        height: auto;
        max-width: 320px;
        border-radius: 8px;
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);

        margin-top: 0;
        margin-right: 0;

    }

    .color-options {
        flex-direction: row;
        margin-top: 200px;
        justify-content: center;
        /* Center buttons horizontally on mobile */
        flex-wrap: wrap;
        margin-left: 60px;
    }

    .color-options button {
        padding: 10px 15px;
        /* Reduced padding for smaller buttons */
        font-size: 0.9rem;
        /* Reduced font size */
        width: auto;
        /* Let the button size adjust based on content */

        /* Limit the maximum width further */

        /* Center the button horizontally */
    }

    .color-options button.active {
        transform: none;
    }


    /* Sapphire Colors Text */
    .sapphire-colors p {
        text-align: justify;
        margin-top: 20px;
        padding-left: 10px;
        padding-right: 10px;
    }

    /* Stone Cards Section */
    .pre-stones-gallery {
        margin-top: 100px;
        text-align: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .stone-cards-section {
        margin-top: 200px;
        margin-bottom: 20px;
    }

    .stone-cards-container {
        display: flex;
        flex-direction: column;
        /* Stack the cards vertically */
        gap: 10px;
        justify-content: center;
        width: 100%;
    }

    .stone-cards-card {
        width: 80%;
        /* Make card width responsive */
        height: 300px;
        /* Adjust height for smaller screens */
        margin-left: 40px;
        margin-top: 20px;
        border-radius: 10px;
        cursor: pointer;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease-in-out;
    }

    .stone-cards-card:hover {
        transform: scale(1.05);
    }

    .stone-cards-card img {
        width: 90%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
    }

    .stone-cards-card h3 {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0;
        padding: 10px;
        background-color: rgba(0, 0, 0, 0.5);
        color: #fff;
        font-size: 1.2rem;
        text-align: center;
        transition: background-color 0.3s ease-in-out;
    }

    .stone-cards-card:hover h3 {
        background-color: rgba(0, 0, 0, 0.9);
    }
}