.home-container {
    margin-top: 220px;
}

.each-slide {
    display: flex;
    height: 70vh;
    /* Restrict the section's height to 70% of the viewport height */
    overflow: hidden;
    position: relative;
    /* To allow positioning of overlay elements */
    text-align: center;

}

.slide-image {
    width: 100%;
    height: 100%;
    /* Adjust height to fill the parent container */
    object-fit: cover;
    margin-top: 0%;
}

/* Overlay styles for heading text */
.slide-overlay {
    position: absolute;
    top: 85%;
    left: 30%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #c3922b;
    /* White text color */

    /* Semi-transparent background */
    padding: 10px 20px;
    border-radius: 5px;
}

.slide-heading {
    font-size: 2.5em;
    font-weight: 1000;
    margin-bottom: 60px;
    font-family: Georgia;
    margin-left: 60px;
    color: #FFFFFA;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.5);
}

/* Updated navigation indicators */
.react-slideshow-container .default-nav {
    position: absolute;
    bottom: 10%;
    /* Move the indicators up to overlap the image */
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    /* Ensure they appear on top of the images */
}

.react-slideshow-container .default-nav .each-slideshow-indicator {
    width: 12px;
    height: 12px;
    margin: 0 5px;
    background-color: #ffffff;
    /* White color for indicators */
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
}

.react-slideshow-container .default-nav .each-slideshow-indicator.active {
    background-color: #c3922b;
    /* Gold color for active indicator */
}

.welcome-section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 20px;
    background-color: #f9f9f9;
    opacity: 0;
    transform: translateY(100px);
    /* Start below the screen */
    transition: opacity 1s, transform 1s;
}

.welcome-section.show {
    opacity: 1;
    transform: translateY(0);
    /* Move to normal position when triggered */
}

.welcome-content {
    flex: 1;
    margin-right: 20px;
    opacity: 0;
    transform: translateX(-50px);
    /* Start off-screen on the left */
    transition: opacity 1s, transform 1s;
}

.welcome-content h1 {
    font-size: 3rem;
    color: #1E90FF;
    margin-bottom: 20px;
    text-align: center;
    margin-bottom: 80px;
    font-family: Georgia;
}

.welcome-content p {
    font-size: 1.2rem;
    color: #555;
    line-height: 1.6;
    margin-bottom: 10px;
}

.welcome-content.show-content {
    opacity: 1;
    transform: translateX(0);
    /* Move to normal position */
}

.welcome-image {
    flex: 1;
    opacity: 0;
    transform: translateX(50px);
    /* Start off-screen on the right */
    transition: opacity 1s, transform 1s;
    max-width: 40%;
}

.welcome-image.show-image {
    opacity: 1;
    transform: translateX(0);
    /* Move to normal position */
}

.welcome-image img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}



/* Gemstone Collection Section */
.gemstone-collection {
    margin-top: 40px;
    text-align: center;
    position: relative;

}

.gemstone-collection h2 {
    font-family: Georgia;
    font-size: 3em;
    color: #1E90FF;
}

.gemstone-cards {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;
    margin-left: 150px;
    max-width: 80%;
}

.gemstone-card {
    flex: 1;
    text-align: center;
    padding: 20px;
    position: relative;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;
}

.gemstone-card:hover {
    transform: translateY(-10px);
}

.gemstone-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
}

.gemstone-info {
    margin-top: 10px;

}

.gemstone-info h3 {
    font-family: Copperplate;
    font-size: 1.5em;
}

.gemstone-explore {
    position: absolute;
    bottom: 10px;
    top: 87%;
    left: 50%;
    transform: translateX(-50%);


}

.gemstone-explore button {
    padding: 10px 20px;
    background-color: #1E90FF;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
    color: #FFFFFA;
}


.arrow-buttons {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    transform: translateY(-50%);
    z-index: 1;

}

.arrow-buttons button {
    padding: 10px 20px;
    background-color: transparent;
    /* Set background to transparent */
    border: none;
    /* Remove the border */
    color: black;
    /* Set arrow color to black */
    font-size: 1.5rem;
    /* Adjust the size of the arrow */
    cursor: pointer;
}

.arrow-buttons button:hover {

    /* Hover effect */
    color: black;
    /* Ensure the color stays black on hover */
}

.left-button {
    margin-left: 30px;
    font-size: 1.5rem;
}

.right-button {
    margin-right: 30px;
    color: black;
}

/* Explore All Button Styling */
.explore-all,
.explore {
    padding: 15px 25px;


    border: none;

    cursor: pointer;
    font-size: 1.2rem;

    transition: background-color 0.3s ease;
}

.explore-all {
    background-color: #1E90FF;
    color: #FFFFFA;
    margin-top: 20px;
    margin-left: 15px;
}

/* Reviews Section Container */
.reviews-section {
    margin: 40px auto;
    padding: 20px;
    max-width: 1200px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

/* Section Heading */
.reviews-section h2 {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
}

/* Elfsight Widget Container */
.reviews-widget {
    margin: 20px auto;
    padding: 10px;
    text-align: center;
}

/* Widget Loading Styles */
.elfsight-app-cdbb79ac-33b9-4e3e-a2bd-4f684aeddcae {
    display: inline-block;
    width: 100%;
    max-width: 800px;
    height: auto;
    min-height: 400px;
    /* Ensures a placeholder size before content loads */
    margin: 0 auto;
    transition: opacity 0.5s ease-in-out;
    opacity: 1;
}

.tripadvisor-widget {
    margin: 40px auto;
    padding: 20px;
    max-width: 1200px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

/* Container for the image gallery */
.gallery-section {
    padding: 40px 20px;
    margin: 0 auto;
    max-width: 1200px;
    /* Center the gallery and limit the max width */
}


/* Masonry Grid Styles */
.gallery-grid {
    display: flex;
    margin-left: -15px;
    /* Negative margin to compensate for column padding */
    width: auto;
}

.gallery-column {
    padding-left: 15px;
    /* Horizontal spacing between columns */
    background-clip: padding-box;
}

/* Individual image items */
.gallery-item {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    /* Optional: Adds rounded corners */
    margin-bottom: 20px;
    /* Space between images */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    /* Subtle shadow for images */
    transition: transform 0.3s ease, opacity 0.3s ease;
    /* Adds a hover effect */
}

/* Image styles */
.gallery-item img {
    width: 100%;
    /* Makes the image responsive within its container */
    height: auto;
    /* Maintain aspect ratio */
    display: block;
    object-fit: cover;
    /* Ensures the image fills its container while maintaining aspect ratio */
    transition: transform 0.3s ease, opacity 0.3s ease;
    /* Smooth hover effect */
}

/* Hover effect for gallery images */
.gallery-item:hover img {
    transform: scale(1.05);
    /* Slight zoom effect */
    opacity: 0.9;
    /* Slightly dim the image */
}

.why-choose-us {
    text-align: center;
    padding: 50px 0;
    /* Remove extra padding and background */
}

.why-choose-us h2 {
    font-size: 2.5rem;
    font-family: Georgia, serif;
    color: #1E90FF;
    margin-bottom: 30px;
    text-transform: uppercase;
    font-weight: bold;
}

.features-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 40px;
    /* Space between items */
    max-width: 1200px;
    margin: 0 auto;
    /* Center the container */
}

.feature-item {
    flex: 1 1 calc(16.66% - 20px);
    /* Each item takes up 1/6 of the row */
    max-width: calc(16.66% - 20px);
    text-align: center;
    padding: 10px 20px;
    border-radius: 0;
    /* Remove any rounded corners */
    background-color: transparent;
    /* Remove background color */
    transition: transform 0.3s ease;
    box-shadow: none;
    /* Remove shadow */
}

.feature-item:hover {
    transform: translateY(-10px);
    /* Slight lift effect on hover */
}

.feature-icon {
    font-size: 3rem;
    color: #FFD700;
    /* Gold color */
    margin-bottom: 15px;
}

.feature-item h3 {
    font-size: 1rem;
    color: #333;
    font-weight: bold;
    text-transform: uppercase;
    margin: 10px 0 5px;
}

.feature-item p {
    font-size: 0.9rem;
    color: #666;
    margin: 0;
    font-weight: normal;
}

/* Mobile responsive styles */
@media (max-width: 768px) {

    /* Adjust home container for smaller screens */
    .home-container {
        margin-top: 250px;
        /* Reduce top margin for compact spacing */
        overflow-x: hidden;
    }

    /* Each slide responsiveness */
    .each-slide {
        display: flex;
        flex-direction: column;
        /* Stack content vertically */
        height: 50vh;
        /* Reduce height for smaller screens */
        position: relative;
        text-align: center;
    }

    /* Responsive slide image */
    .slide-image {
        width: 100%;
        height: 800px;
        object-fit: cover;
        /* Maintain aspect ratio */
        margin-top: 0;
    }

    /* Responsive slide overlay */
    .slide-overlay {
        position: absolute;
        top: 90%;
        /* Adjust position for better visibility */
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        color: #c3922b;
        padding: 5px 10px;
        /* Compact padding */
        font-size: 0.9rem;
        /* Smaller text size for mobile */
        border-radius: 5px;
    }

    /* Responsive slide heading */
    .slide-heading {
        font-size: 2.5em;
        /* Reduce heading size */
        font-weight: 700;
        /* Adjust font weight */
        margin-bottom: 300px;
        /* Reduce margin for compact layout */
        margin-left: 0;
        /* Center-align heading on smaller screens */
        color: #FFFFFA;
        background-color: rgba(0, 0, 0, 0.6);
        /* Increase opacity for better contrast */
        border-radius: 8px;
        padding: 5px 10px;
        /* Add padding for readability */
        text-align: center;
    }

    /* Responsive navigation indicators */
    .react-slideshow-container .default-nav {
        bottom: 5%;
        /* Adjust position closer to the bottom */
        left: 50%;
        transform: translateX(-50%);
    }

    .react-slideshow-container .default-nav .each-slideshow-indicator {
        width: 10px;
        /* Reduce indicator size */
        height: 10px;
        margin: 0 4px;
        /* Adjust spacing */
        background-color: #ffffff;
    }

    .react-slideshow-container .default-nav .each-slideshow-indicator.active {
        background-color: #c3922b;
    }

    .welcome-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 30px 10px;
        /* Reduce padding for mobile */
        background-color: #f9f9f9;
        opacity: 0;
        transform: translateY(100px);
        /* Keep the animation properties */
        transition: opacity 1s, transform 1s;
    }

    /* Responsive welcome content */
    .welcome-content {
        flex: none;
        /* Remove flex for column layout */
        margin-right: 0;
        /* No margin for stacking */
        margin-bottom: 20px;
        /* Add space between text and image */
        text-align: center;
        /* Center-align text for mobile */
        opacity: 0;
        transform: translateY(50px);
        /* Slight animation from below */
        transition: opacity 1s, transform 1s;
    }

    .welcome-content h1 {
        font-size: 2.5rem;
        /* Adjust heading size */
        margin-bottom: 15px;
        /* Reduce bottom margin */
    }

    .welcome-content p {
        font-size: 1rem;
        /* Adjust paragraph text size */
        line-height: 1.4;
        /* Slightly tighter line spacing */
    }

    /* Responsive welcome image */
    .welcome-image {
        flex: none;
        /* Remove flex for column layout */
        max-width: 80%;
        /* Reduce image width for mobile */
        margin-top: 10px;
        /* Add space above the image */
        opacity: 0;
        transform: translateY(50px);
        /* Slight animation from below */
        transition: opacity 1s, transform 1s;
    }

    .welcome-image img {
        max-width: 100%;
        height: auto;
        border-radius: 8px;
        /* Slightly tighter radius for smaller screens */
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
        /* Subtle shadow for mobile */
    }

    .gemstone-collection {
        margin-top: 20px;
        text-align: center;
    }

    .gemstone-collection h2 {
        font-size: 2em;
        /* Adjust heading size for mobile */
    }

    .gemstone-cards {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        /* Prevent wrapping of cards */
        gap: 0;
        /* No gap between cards */
        margin-left: 0;
        /* Remove left margin for centering */
        max-width: 90%;
        /* Use full width of the screen */
        overflow: hidden;
        /* Hide extra cards outside the view */
    }

    .gemstone-card {
        flex: none;
        /* Prevent cards from shrinking */
        width: 90%;
        /* Card occupies most of the viewport width */
        text-align: center;

        margin: 0 auto;
        /* Center card horizontally */
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        /* Adjust shadow for mobile */
        border-radius: 10px;
        transition: transform 0.3s ease-in-out;
    }

    .gemstone-image {
        width: 80%;
        height: auto;
        /* Maintain aspect ratio */
        max-height: 180px;
        /* Adjust image size for smaller screens */
        object-fit: cover;
        border-radius: 10px;
        margin-left: 30px;
    }

    .gemstone-info h3 {
        font-size: 1.2em;
        /* Adjust text size for mobile */
        margin-left: 20px;
    }

    .gemstone-explore {
        display: none;
    }

    .arrow-buttons {
        position: absolute;
        top: 50%;
        /* Align arrows vertically */
        left: 0;
        right: 0;
        display: flex;
        justify-content: space-between;
        width: 100%;
        transform: translateY(-50%);
        z-index: 1;
    }

    .arrow-buttons button {
        padding: 10px;
        /* Adjust padding for smaller buttons */
        font-size: 1.2rem;
        /* Adjust size of the arrows */
        color: black;
        /* Keep arrow color black */
        background-color: transparent;
        /* Keep background transparent */
        border: none;
        cursor: pointer;
    }

    .arrow-buttons button:hover {
        color: #1E90FF;
        /* Add a hover effect */
    }

    .left-button {
        margin-left: 15px;
    }

    .right-button {
        margin-right: 15px;
    }

    /* Explore All Button Styling */
    .explore-all {
        background-color: #1E90FF;
        color: #FFFFFA;
        margin-top: 15px;
        font-size: 1rem;
        /* Adjust button size */
        margin-right: 30px;
        /* Adjust padding */
    }

    .gallery-section {
        padding: 20px 10px;
        /* Reduce padding for smaller screens */
        margin: 0 auto;
        max-width: 100%;
        /* Use full screen width */
    }

    .gallery-grid {
        display: block;
        /* Stack columns vertically on smaller screens */
        margin-left: 0;
        /* Remove negative margin */
        width: 100%;
        /* Ensure full width */
    }

    .gallery-column {
        padding-left: 0;
        /* Remove horizontal spacing */
        margin-bottom: 20px;
        /* Add spacing between stacked columns */
        background-clip: border-box;
        /* Adjust clipping for smaller screens */
        width: 100%;
        /* Make columns full width */
    }

    /* Individual image items */
    .gallery-item {
        margin-bottom: 15px;
        /* Reduce spacing between images */
        border-radius: 8px;
        /* Adjust border radius for smaller images */
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        /* Subtle shadow for mobile */
    }

    /* Image styles */
    .gallery-item img {
        width: 100%;
        /* Ensure images are responsive */
        height: auto;
        /* Maintain aspect ratio */
        object-fit: cover;
        /* Fill container proportionally */
        border-radius: 8px;
        /* Match container radius */
    }

    /* Adjust hover effect for smaller screens */
    .gallery-item:hover img {
        transform: scale(1.02);
        /* Slightly reduced zoom effect */
        opacity: 0.95;
        /* Subtle dimming */
    }

    .why-choose-us {
        padding: 30px 0;
        /* Reduce padding for smaller screens */
    }

    .why-choose-us h2 {
        font-size: 2rem;
        /* Adjust font size for better readability on mobile */
        margin-bottom: 20px;
        /* Reduce spacing */
    }

    .features-container {
        flex-direction: column;
        /* Stack items vertically */
        gap: 20px;
        /* Adjust gap between items */
        max-width: 100%;
        /* Ensure full width */
        padding: 0 15px;
        /* Add padding for smaller screens */
    }

    .feature-item {
        flex: 1 1 100%;
        /* Each item takes full width */
        max-width: 100%;
        /* Ensure full width */
        text-align: center;
        /* Center align content */
        padding: 15px;
        /* Add spacing within items */
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        /* Subtle shadow for separation */
        border-radius: 8px;
        /* Add rounded corners for better design */
        background-color: #f9f9f9;
        /* Add a light background for visual separation */
        transition: transform 0.3s ease;
        /* Smooth transition for hover effect */
    }

    .feature-item:hover {
        transform: translateY(-5px);
        /* Slight lift effect on hover */
    }

    .feature-icon {
        font-size: 2.5rem;
        /* Adjust icon size */
        margin-bottom: 10px;
        /* Reduce spacing */
    }

    .feature-item h3 {
        font-size: 1.2rem;
        /* Adjust heading size */
        margin: 10px 0;
        /* Balance spacing */
    }

    .feature-item p {
        font-size: 1rem;
        /* Adjust paragraph size */
        color: #555;
        /* Slightly darker text color for better readability */
    }
}