.rev-container {
    margin-top: 220px;
    padding-top: 0;
}

.rev-image-section {
    height: calc(100vh - 70px);
    /* Full viewport height minus navbar height */
    max-height: 70vh;
    /* Limit height to 70% of the viewport */
    overflow: hidden;
    position: relative;
    /* Required for positioning the heading */
}

.rev-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Heading positioned at the bottom left */
.rev-heading {
    position: absolute;
    bottom: 20px;
    left: 20px;
    font-size: 3em;
    font-family: Georgia;
    color: white;
    /* White text color */
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent black background */
    padding: 10px 15px;
    border-radius: 5px;
}

.rev-section {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    gap: 20px;
}

.rev-form {
    width: 50%;
    max-height: 600px;
    background: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.rev-list {
    width: 45%;
    background: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.rev-form h2,
.rev-list h2 {
    font-size: 2em;
    font-family: Georgia;
}

.rrev-form form {
    max-width: 95%;
}

.rev-form form label {
    display: block;
    margin: 10px 0 5px;
    font-weight: bold;
}

.rev-form form input,
.rev-form form textarea,
.rev-form form button {
    width: 100%;
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.rev-form form button {
    background-color: #000;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.3s;
    width: 200px;
}

.rev-form form button:hover {
    background-color: #444;
}

.rev-form .ReactStars {
    margin: 10px 0;
}

.rev-list h2 {
    margin-bottom: 20px;
}

.rev-item {
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
}

.rev-item .ReactStars {
    margin: 5px 0;
}

.rev-images {
    display: flex;
    flex-wrap: wrap;
    /* Allows wrapping of elements to the next row */
    gap: 10px;
    /* Space between images */
    justify-content: center;
    /* Centers the images horizontally */
    align-items: flex-start;
    /* Aligns images to the top */
}

.rev-images img {
    object-fit: cover;
    /* Ensures images don't stretch */
    border-radius: 8px;
    /* Optional: Rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    /* Adds a subtle shadow */
    margin-bottom: 10px;
    /* Adds vertical spacing */
    height: auto;
    /* Keeps the aspect ratio intact */
}

/* Specific image sizes for Masonry effect */
.rev-images img:nth-child(1) {
    width: calc(30% - 10px);
    height: auto
        /* 50% width */
}

.rev-images img:nth-child(2) {
    width: calc(35% - 10px);
    height: 420px;

    /* 25% width */
}

.rev-images img:nth-child(3) {
    width: calc(75% - 10px);
    /* 75% width */
}

.rev-images img:nth-child(4) {
    width: calc(30% - 10px);
    /* 33.33% width */
}

.rev-images img:nth-child(5) {
    width: calc(30.66% - 10px);
    /* 66.66% width */
}

.rev-images img:nth-child(6) {
    width: calc(30% - 10px);
    /* 40% width */
}

.rev-images img:nth-child(7) {
    width: calc(30% - 10px);
    /* 60% width */
}

.rev-images img:nth-child(8) {
    width: calc(30% - 10px);
    /* 50% width */
}

.rev-images img:nth-child(9) {
    width: calc(30% - 10px);
    /* 30% width */
}

.rev-images img:nth-child(10) {
    width: calc(50% - 10px);
    /* 70% width */
}

@media screen and (max-width: 768px) {
    .rev-container {
        margin-top: 250px;
        /* Reduce margin for smaller screens */
    }

    .rev-image-section {
        height: calc(100vh - 50px);
        /* Adjust height to fit smaller screens */
        max-height: 60vh;
        /* Reduce max height for better layout */

    }

    .rev-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        /* Ensure the image covers the container */
    }

    .rev-heading {
        bottom: 15px;
        /* Adjust position */
        left: 10px;
        /* Adjust position */
        font-size: 1.8em;
        /* Reduce font size for smaller screens */
        padding: 8px 12px;
        /* Adjust padding */
    }

    .rev-section {
        flex-direction: column;
        /* Stack the form and list vertically */
        padding: 10px;
        /* Reduce padding for smaller screens */
        gap: 20px;
        /* Maintain spacing between stacked elements */
    }

    .rev-form,
    .rev-list {
        width: 90%;
        /* Full width for both columns */
        padding: 15px;
        /* Adjust padding for better readability */
        max-height: none;
        /* Remove height restrictions */
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
        /* Softer shadow for smaller screens */
    }

    .rev-form {
        order: 1;
        /* Ensure it appears at the top */

        justify-items: center;
    }

    .rev-list {
        order: 2;
        /* Ensure it appears at the bottom */

    }

    .rev-form h2,
    .rev-list h2 {
        font-size: 1.5em;
        /* Reduce heading size for smaller screens */
    }

    .rev-form form input,
    .rev-form form textarea,
    .rev-form form button {
        font-size: 0.9em;
        /* Adjust font size for smaller screens */
        padding: 8px;
        /* Reduce padding for better fit */
    }

    .rev-form form button {
        width: 100%;
        /* Buttons span full width on smaller screens */
    }

    .rev-item {
        padding-bottom: 8px;
        /* Adjust spacing between items */
        margin-bottom: 15px;
        /* Adjust margin for better spacing */
    }

    .rev-images {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: center;
        align-items: flex-start;
    }

    .rev-images img {
        width: 100%;
        height: auto;
        object-fit: cover;
        border-radius: 8px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        margin-bottom: 10px;
    }

    /* Optional: Customize specific image sizes for a better layout */
    .rev-images img:nth-child(1),
    .rev-images img:nth-child(2),
    .rev-images img:nth-child(3),
    .rev-images img:nth-child(4),
    .rev-images img:nth-child(5),
    .rev-images img:nth-child(6),
    .rev-images img:nth-child(7),
    .rev-images img:nth-child(8),
    .rev-images img:nth-child(9),
    .rev-images img:nth-child(10) {
        width: 48%;
        /* Each image takes roughly half the width with a gap */
    }

    .rev-images img:nth-child(2) {
        height: 200px;
    }
}