.contact-us-container {
    margin-top: 220px;
    padding-top: 0;
}

.contact-us-image-section {
    height: calc(100vh - 70px);
    /* Full viewport height minus navbar height */
    max-height: 70vh;
    /* Limit height to 70% of the viewport */
    overflow: hidden;

    /* Required for positioning the heading */
}

.contact-us-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Heading positioned at the bottom left */
.contact-us-heading {
    position: absolute;
    bottom: 20px;
    left: 20px;
    font-size: 3em;
    font-weight: bold;
    color: white;
    /* White text color */
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent black background */
    padding: 10px 15px;
    border-radius: 5px;
    font-family: Georgia;
    margin-left: 30px;
}

/* Form Section */
.contact-us-form-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 20px;
    background-color: #f9f9f9;
    /* Light background color */
}

.contact-us-form {
    width: 100%;
    max-width: 600px;
    /* Limit the width of the form */
    background: #ffffff;
    /* Form background color */
    padding: 20px 30px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    /* Subtle shadow for form */
}

.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.form-group label {
    font-weight: bold;
    margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1em;
}

.form-group textarea {
    resize: none;
    height: 120px;
    /* Default height for textarea */
}

.form-submit-button {
    background-color: #000000;
    /* Button color */
    color: white;
    font-size: 1em;
    font-weight: bold;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.form-submit-button:hover {
    background-color: #444444;
    /* Darker shade on hover */
}

.contact-details-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 600px;
    padding: 20px;


    margin-top: 40px;

    margin-left: 28%;
}

.contact-details-section h3 {
    font-size: 3rem;
    margin-bottom: 10px;
    font-family: Georgia;
    color: #1E90FF;
}

.contact-details-section p {
    font-size: 2rem;
    margin: 5px 0;
    text-align: center;
    color: #808080;
}

/* Location Map Section */
.location-map-section {
    padding: 40px 20px;
    /* Top and bottom padding, left and right margins */
    margin: 0 auto;
    /* Center the map */
    max-width: 900px;
    /* Limit the maximum width for the map section */
    text-align: center;
}

.map-heading {
    font-size: 3em;
    color: #1E90FF;
    margin-bottom: 20px;
    font-family: Georgia;
}

.location-map {
    width: 100%;
    border: none;
    border-radius: 10px;
    /* Rounded corners for the map */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    /* Subtle shadow for aesthetics */
}

@media (max-width: 768px) {
    .contact-us-container {
        margin-top: 250px;
        /* Reduced top margin for smaller screens */
        padding-top: 0;
        overflow-x: hidden;
    }

    .contact-us-image-section {
        height: 50vh;
        /* Reduce height to 50% of the viewport */
        max-height: 50vh;
        /* Limit max height */
        width: 100%;
        position: relative;
    }

    .contact-us-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .contact-us-heading {

        bottom: 10px;
        left: 10px;
        font-size: 1.5em;
        /* Reduced font size for smaller screens */
        font-weight: bold;
        color: white;
        background-color: rgba(0, 0, 0, 0.6);
        /* Slightly darker background for contrast */
        padding: 8px 12px;
        /* Adjust padding for smaller screens */
        border-radius: 5px;
        font-family: Georgia;
        margin-left: 0;
        /* Adjust left margin */
        text-align: center;
        /* Center-align text */
        width: calc(100% - 30px);
        /* Ensure the heading doesn't overflow */
    }

    .contact-us-form-section {
        flex-direction: column;
        padding: 20px 10px;
        /* Reduce padding for smaller screens */
    }

    .contact-us-form {
        max-width: 100%;
        /* Allow form to take full width */
        padding: 15px 20px;
        /* Adjust padding for smaller screens */
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
        /* Slightly lighter shadow */
        width: 90%;
    }

    .form-group {
        margin-bottom: 15px;
        /* Reduce margin between form groups */
    }

    .form-group label {
        font-size: 0.9em;
        /* Adjust label font size for readability */
    }

    .form-group input,
    .form-group textarea {
        font-size: 0.9em;
        /* Adjust input and textarea font size */
        padding: 8px;
        /* Reduce padding */
    }

    .form-group textarea {
        height: 100px;
        /* Reduce textarea height */
    }

    .form-submit-button {
        font-size: 0.9em;
        /* Adjust button font size */
        padding: 8px 12px;
        /* Reduce button padding */
    }

    .contact-details-section {
        margin: 0;
        /* Center align on smaller screens */
        padding: 15px;
        /* Reduce padding for smaller devices */
    }

    .contact-details-section h3 {
        font-size: 2rem;
        /* Reduce heading font size */
        text-align: center;
        /* Ensure centered alignment */
    }

    .contact-details-section p {
        font-size: 1.5rem;
        /* Adjust paragraph font size */
        text-align: center;
        /* Maintain centered alignment */
    }

    .location-map-section {
        padding: 20px 10px;
        /* Reduce padding for smaller screens */
    }

    .map-heading {
        font-size: 2rem;
        /* Adjust font size for smaller screens */
        margin-bottom: 15px;
        /* Reduce margin for better spacing */
    }

    .location-map {
        border-radius: 5px;
        /* Slightly smaller rounded corners */
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        /* Lighter shadow for mobile devices */
    }
}