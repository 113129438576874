.about-us-video {
    margin-top: 220px;
    width: 100%;
    height: 600px;
    object-fit: cover;
}

.about-us-content h1 {
    text-align: center;
    font-size: 3em;
    font-family: Georgia;
    color: #1E90FF;
}

/* Two Columns Section */
.about-us-content-section {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding: 40px 20px;
    background-color: #ffffff;
}



.left-column {
    width: 60%;
    font-size: 1em;
    line-height: 1.6;
}

.left-column-heading {
    font-size: 2.5em;
    font-family: Georgia;
    margin-bottom: 40px;
    color: #1E90FF;
    text-align: center;
    margin-top: 0%;
}

.left-column p {
    margin-bottom: 20px;
    color: #808080;
}

.right-column {
    width: 35%;
    /* No need for display: flex */
}

.right-column-image-1 {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    /* Optional: Add spacing between images */
}

.right-column-image-2 {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Chairman's Message Section */
.chairman-message-section {
    text-align: center;
    margin: 50px 20px;
    /* Add spacing above and below the section */
}

.chairman-message-heading {
    font-size: 3em;
    font-family: Georgia;
    color: #1E90FF;
    /* Dark gray for better readability */
    margin-bottom: 20px;
}

/* Chairman's Details Section */
.chairman-details-section {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding: 40px 20px;
    margin-top: 0%;
    padding-top: 0%;
}

.chairman-left-column {
    width: 50%;
    /* Left column takes 40% of the width */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
}

.chairman-image {
    width: 500px;
    height: 700px;
    margin-bottom: 30px;

}

.chairman-right-column {
    width: 45%;
    /* Right column takes 55% of the width */
    font-size: 1em;
    line-height: 1.8;
    /* Increased line height for readability */
}

.chairman-right-column p {
    margin-bottom: 20px;
    margin-bottom: 0%;
    /* Add spacing between paragraphs */
    color: #808080;
}

.chairman-signature p {
    color: black;
}

.about-us-team-section {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding: 40px 20px;
    background-color: #ffffff;
}



.team-left-column {
    width: 60%;
    font-size: 1em;
    line-height: 1.6;
}

.team-left-column-heading {
    font-size: 2.5em;
    font-family: Georgia;
    margin-bottom: 40px;
    color: #1E90FF;
    text-align: center;
    margin-top: 0%;
}

.team-left-column p {
    margin-bottom: 20px;
    color: #808080;
}

.team-right-column {
    width: 35%;
    /* No need for display: flex */
}

.team-right-column-image-1 {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin-top: 60px;
    /* Optional: Add spacing between images */
}

@media screen and (max-width: 768px) {
    .about-us-image-section {
        margin-top: 100px;
    }

    .about-us-video {
        margin-top: 150px;
        /* Reduce top margin */
        height: 500px;
        /* Adjust height for smaller screens */
    }

    .about-us-content h1 {
        font-size: 2em;
        /* Reduce font size for smaller screens */
        line-height: 1.2;
        /* Adjust line height for readability */
        padding: 0 10px;
        /* Add some padding for spacing if needed */
    }

    .about-us-content-section {
        flex-direction: column;
        /* Stack columns vertically */
        align-items: center;
        /* Center align for better appearance */
        gap: 30px;
        /* Optional: Add more spacing between sections */
    }

    .left-column,
    .right-column {
        width: 100%;
        /* Take full width on smaller screens */
        text-align: center;
        /* Optional: Center text for consistency */
    }

    .left-column-heading {
        font-size: 2em;
        /* Adjust heading size for mobile */
        margin-bottom: 20px;
        /* Reduce bottom margin */
    }

    .right-column-image-1,
    .right-column-image-2 {
        margin: 0 auto;
        /* Center images */
    }

    .chairman-details-section {
        flex-direction: column;
        /* Stack columns vertically */
        align-items: center;
        /* Center align for better appearance */
        gap: 30px;
        /* Add spacing between columns */
    }

    .chairman-left-column,
    .chairman-right-column {
        width: 100%;
        /* Take full width on smaller screens */
        text-align: center;
        /* Optional: Center text for consistency */
    }

    .chairman-image {
        width: 100%;
        /* Make the image responsive */
        height: auto;
        /* Maintain aspect ratio */
        margin-right: 20px;
        /* Limit max width for better display */
        margin-bottom: 20px;
    }

    .chairman-message-heading {
        font-size: 2em;
        /* Adjust heading size for mobile */
        margin-bottom: 20px;
        /* Reduce bottom margin */
    }

    .about-us-team-section {
        flex-direction: column;
        /* Stack the columns vertically */
        align-items: center;
        /* Center-align the content */
        gap: 30px;
        /* Add spacing between the stacked columns */
    }

    .team-left-column,
    .team-right-column {
        width: 100%;
        /* Make columns take full width */
        text-align: center;
        /* Center-align text for mobile layout */
    }

    .team-left-column-heading {
        font-size: 1.8em;
        /* Adjust heading size for smaller screens */
        margin-bottom: 20px;
        /* Reduce bottom margin */
    }

    .team-right-column-image-1 {
        width: 100%;
        /* Make the image responsive */
        height: auto;
        /* Maintain aspect ratio */
        max-width: 400px;
        /* Limit max width for better mobile display */
        margin-top: 20px;
        /* Reduce top margin for better spacing */
    }
}