/* Footer Container */
.footer {
    position: relative;
    background-image: url('../assets/Footer/ai-generated-minimalist-backdrop-adorned-with-sparkling-diamonds-exuding-brilliance-and-luxury-free-photo.jpeg');
    /* Adjust the path if needed */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: #fff;
    padding: 50px 20px;
    z-index: 1;
    overflow: hidden;
    /* Prevent content overflow */
}

.footer::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    /* Overlay with 60% opacity */
    z-index: -1;
    /* Place it behind the content */
}

/* Footer Content */
.footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    z-index: 2;
    /* Ensure content is above the overlay */
}

.footer-section {
    flex: 1;
    min-width: 250px;
    margin: 10px 20px;
}

.footer-section h4 {
    font-size: 25px;
    font-family: Georgia;
    margin-bottom: 15px;
    text-transform: uppercase;
    font-weight: bold;
}

.footer-section p,
.footer-section li,
.footer-section a {
    font-size: 15px;
    margin: 5px 0;
    line-height: 1.6;
    color: #fff;
    text-decoration: none;
}

.footer-section ul {
    list-style: none;
    padding: 0;
}

/* Subscription Form */
.footer-section.signup form {
    display: flex;
    margin-top: 10px;
    gap: 10px;
}

.footer-section.signup input[type="email"] {
    flex: 1;
    padding: 10px;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    outline: none;
}

.footer-section.signup button {
    background-color: #ffd700;
    /* Gold color */
    color: #000;
    border: none;
    padding: 10px 15px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.footer-section.signup button:hover {
    background-color: #e6c200;
    /* Darker gold */
}

/* Social Icons */
.footer-section.signup .social-icons {
    margin-top: 20px;
    display: flex;
    gap: 15px;

    color: #fff;
}

.social-icons a {
    color: #fff;
    font-size: 2em;
}

.footer-section.signup .social-icons i {
    font-size: 24px;
    cursor: pointer;
    color: #fff;
    transition: color 0.3s ease;
}

.footer-section.signup .social-icons i:hover {
    color: #ffd700;
    /* Gold on hover */
}

/* Footer Bottom Section */
.footer-bottom {
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
    color: #ccc;
    padding-top: 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.footer-bottom .payment-methods {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    gap: 15px;
    font-size: 4em;
}

.footer-bottom .payment-methods i {
    font-size: 32px;
    color: #ccc;
    transition: color 0.3s ease;
}

.footer-bottom .payment-methods i:hover {
    color: #ffd700;
}

.footer-bottom a {
    color: #ccc;
    text-decoration: none;
}

@media screen and (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        /* Stack the content vertically */
        align-items: center;
        /* Center align content */
    }

    .footer-section {
        width: 100%;
        /* Make each section take full width */
        text-align: center;
        /* Center align text */
        margin: 15px 0;
        /* Adjust margin */
    }

    .footer-section h4 {
        font-size: 20px;
        /* Adjust heading font size */
        margin-bottom: 10px;
        /* Adjust margin */
    }

    .footer-section.signup form {
        flex-direction: column;
        /* Stack form elements vertically */
        align-items: center;
        /* Center align form elements */
    }

    .footer-section.signup input[type="email"] {
        width: 80%;
        /* Make the input field width responsive */
        margin-bottom: 10px;
        /* Add margin at the bottom */
    }

    .footer-section.signup button {
        width: 80%;
        /* Make the button width responsive */
    }

    .footer-section.signup .social-icons {
        justify-content: center;
        /* Center align social icons */
        gap: 20px;
    }

    .footer-bottom .payment-methods {

        /* Stack payment methods vertically */
        gap: 10px;
    }

    .footer-bottom .payment-methods i {
        font-size: 28px;
        /* Adjust icon size */
    }

    .footer-section.signup button {
        padding: 5px 4px;
        /* Adjust button padding */
        border-radius: 3px;
        width: 80px;
    }

    .footer-bottom {
        font-size: 12px;
        /* Reduce font size for better mobile readability */
    }
}